<template>
<div>

  <div style="display: flex;flex-direction: row;justify-content: space-between;">
    <div style="display: flex">
      <el-select v-model="value" placeholder="请选择" style="margin-top: 8px;margin-left: 2%" @change="getInitData">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>

      <el-select v-model="getMonth" placeholder="请选择" style="margin: 8px" @change="getInitData">
        <el-option
          v-for="item in months"
          :key="item.label"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>


    <p style="font-size: 2.2em;font-weight: bold;margin-top: 10px">{{"基础数据："+value}}</p>
    <el-button @click="exportTable"    type="primary" style="max-height: 50px;margin: 8px">导出报表</el-button>
  </div>

  <el-table
    id="myTable"
    :data="initData"
    border
    style=";margin-left: 2%;margin-top: 10px">
    <el-table-column
      fixed
      label="机组"
      prop="stationName"
      width="190">
    </el-table-column>

    <el-table-column v-for="(item,index) in days" :key="item" :label="item" width="90" >
      <template slot-scope="scope">
        <span v-if="scope.row.meterDataList[index].id === null">暂无</span>
        <span v-if="scope.row.meterDataList[index].id !== null">{{ scope.row.meterDataList[index].meterData }}</span>
      </template>
    </el-table-column>


    <el-table-column
      label="操作"
      width="200">
      <template slot-scope="scope">
        <el-button @click="editPlan(scope.row)" type="text" size="small">编辑</el-button>
      </template>
    </el-table-column>


    <el-dialog :title="编辑数据" :visible.sync="Visible" :append-to-body="true" width="40%" top="calc((100vh - 600px) / 2)" :center=true :close-on-click-modal=false>
      <el-form label-width="90px">

            <el-form-item label="机组">
              <el-input style="max-width: 300px" readonly="true" v-model="meter.stationName"></el-input>
            </el-form-item>


            <el-form-item label="日期">
              <el-select v-model="updateTime" placeholder="请选择" style="width: 300px">
              <el-option
                v-for="item in days"
                :key="item"
                :label="item"
                :value="(days || []).findIndex((i) => i === item)">
              </el-option>
              </el-select>
            </el-form-item>

              <el-form-item label="数值">
                <el-input style="max-width: 300px" v-model="updateData"></el-input>
              </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveMeter(meter.stationName,updateData,meter.meterDataList[updateTime].id)">确定更新</el-button>
        <el-button type="danger" @click="Visible = false">取消</el-button>
      </span>
    </el-dialog>

  </el-table>

</div>
</template>

<script>
import clientUtils from "@/js/clientUtils";
import  {exportExcelByTableId} from "@/js/excel";
export default {
  name: "DayFormInitData",
  data(){
    return{
      value:"",
      initData:[],
      days:[],
      Visible : false,
      meter:{},
      updateTime:{},
      updateData:0,
      updateMeter:{},
      options: [{
        value: '水',
        label: '水'
      }, {
        value: '电',
        label: '电'
      }, {
        value: '热',
        label: '热'
      }],
      months: [{        //日报表月份切换select
        value: '水',
        label: '11月'
      }]
      ,
      getMonth:""     //查询的日报表的月份
    }},
  created() {
     this.value="水"
  },
  mounted() {
    this.initDays()
    this.getInitData()
  },
  methods:{
    getInitData:function (){
      let str = this.getMonth.split("-")
      let year = str[0]
      let month = str[1]
      this.days.length = 0;
      this.days = this.getDaysInMonth(year,month)
      const that = this
      clientUtils.get("/system/meter/findAll?type="+that.value+"&month="+this.getMonth, function (res) {
        if (res.data != null) {
          console.log("数据："+res.data.data)
          that.initData=res.data.data
        }
      })
    },
    initDays:function (){    //拼接title的方法，在挂载期调用
      let myDate = new Date();
      let year = myDate.getFullYear()
      let month = myDate.getMonth()+1
      this.days = this.getDaysInMonth(year,month)
      this.getMonth = year+"-"+month;
      this.months.length = 0;

      //定义月份切换的值
      let a1;
      let a2;
      let a3;
      let a4;
      let a5;


      if (month >= 11){
        a1 = {lable:year+"-"+"11",value:year+"-"+"11"}
        a2 = {lable:year+"-"+"12",value:year+"-"+"12"}
        a3 = {lable:(year+1)+"-"+"1",value:(year+1)+"-"+"1"}
        a4 = {lable:(year+1)+"-"+"2",value:(year+1)+"-"+"2"}
        a5 = {lable:(year+1)+"-"+"3",value:(year+1)+"-"+"3"}
      }else {
        a1 = {lable:year-1+"-"+"11",value:year-1+"-"+"11"}
        a2 = {lable:year-1+"-"+"12",value:year-1+"-"+"12"}
        a3 = {lable:(year)+"-"+"1",value:(year)+"-"+"1"}
        a4 = {lable:(year)+"-"+"2",value:(year)+"-"+"2"}
        a5 = {lable:(year)+"-"+"3",value:(year)+"-"+"3"}
      }

      this.months.push(a1)
      this.months.push(a2)
      this.months.push(a3)
      this.months.push(a4)
      this.months.push(a5)
    },
    getDaysInMonth:function (year, month) {
      const daysOfMonth = [];
      month = parseInt(month, 10);
      const lastDayOfMonth = new Date(year, month, 0).getDate();
      for (let i = 1; i <= lastDayOfMonth; i++) {
        if (i < 10) {
          daysOfMonth.push(month+"月" + i+"日"); //判断是部否小于10，如果小于加0，，例如“01”
        } else {
          daysOfMonth.push(month+"月"+i+"日");
        }
      }
      return daysOfMonth;
    },
    //点击编辑弹出dialog
    editPlan: function (meter) {
      this.Visible = true;
      this.meter = JSON.parse(JSON.stringify(meter));
    },
    back:function(){
      this.Visible =false
    },
    saveMeter:function (stationName,updateData,id){
      this.updateMeter.id = id;
      this.updateMeter.stationName = stationName;
      this.updateMeter.updateData = updateData;
      this.updateMeter.type = this.value;
      const that =this;
      let updatejosn = JSON.stringify(this.updateMeter);
      clientUtils.post("/system/meter/update", updatejosn, function (res) {
        if (res.data!=null) {
          // 编辑用户信息成功
          that.getInitData();
          that.back();
          that.$message({
            message: '数据更新成功',
            type: 'success'
          });
        }
      })
    },
    exportTable:function (){
      exportExcelByTableId("myTable","基础数据:"+this.value)
    }
  }
}
</script>

<style scoped>

</style>
